import React, { Component} from 'react'
import { connect } from 'react-redux'
import Header from '../Header'
import Nav from '../Nav'
import ReactJson from 'react-json-view'
import  datos  from '../../lib/global/data'
import Button from 'react-bootstrap/Button'
import { Row, Col, Card } from 'react-bootstrap'
import { CopyBlock, dracula, ocean } from "react-code-blocks";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

class PedidosDetalle extends Component {
    constructor(){
        super()
        this.state = {
            code: '',
            language: ''
        }
    }
    render(){

        return(
            <div className="fluid">
       <Header/>   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Consultar detalle de un pedido</h1>
      </div>



      <Row className="mb-5">
          <Col md={7}>


<h6><Button variant="primary" size="sm">GET</Button> /ordenes/{"{referencia}"}</h6>
<hr />

<h6>Parámetros</h6>
<Card className="mb-3">
    <Card.Body>
        <h6 className="mb-0">referencia <span style={{ fontWeight: 100 }}>String</span> <span className="text-danger">required</span> <span style={{ fontWeight: 100 }}>Referencia única del pedido</span></h6>
    </Card.Body>
</Card>

<h6>Respuesta</h6>
<Card className="mb-3" style={{ background:'#0c0d0e' }}>
    <Card.Body>
    <ReactJson 
name={false}
  theme="brewer"
  indentWidth="4"
  src={{
    "_id": "627e5e6feeae55dd057e6506",
    "pedido": "referencia",
    "propietario": "76163495K",
    "bultos": "126",
    "congelado": "0",
    "fechadespacho": "2022-06-17",
    "guia": "",
    "nota": "referencia",
    "orden": {
        "number": "referencia",
        "date_completed": "2022-05-13 09:34:05",
        "date_completed_gmt": "2022-05-13 09:34:05",
        "meta_data": [
            {
                "key": "dlp_volumen_total",
                "value": "1",
                "id": "805053"
            }
        ],
        "id": "referencia",
        "shipping": {
            "last_name": "",
            "company": "",
            "state": "",
            "first_name": "CD DELPA",
            "address_2": "referencia",
            "city": "",
            "postcode": "",
            "country": "",
            "address_1": "El Juncal 100, Quilicura, Región Metropolitana, Chile, SANTIAGO"
        },
        "billing": {
            "first_name": "CD DELPA",
            "state": "",
            "email": "jurra@delpa.cl",
            "phone": "56995188829",
            "last_name": "",
            "company": "",
            "address_1": "El Juncal 100, Quilicura, Región Metropolitana, Chile, SANTIAGO",
            "address_2": "referencia",
            "city": "Santiago",
            "postcode": "",
            "country": "Chile"
        }
    },
    "ot": "697930",
    "refrigerado": "0",
    "tiposervicio": "100",
    "idconductor": "623a6f3d642a32659e30ec05",
    "estado_entrega": {
        "_id": "6256ef5e509b7c328bd17577",
        "codigo_estado": "3",
        "titulo": "En tránsito",
        "color": "#2a51af",
        "fecha": 1652453106626
    },
    "sub_estado_entrega": {
        "_id": "6256ef99509b7c328bd175b2",
        "codigo_estado": "2",
        "titulo": "Iniciar ruta",
        "color": "#0a48cc",
        "fecha": 1652453106626
    },
    "comuna": "Quilicura",
    "lat": "-33.3336989",
    "lng": "-70.7232589",
    "pais": "Chile",
    "manifiesto": "616",
    "conductor": {
        "_id": "623a6f3d642a32659e30ec05",
        "nombres": "PFTW53",
        "apellidos": "BOXER",
        "rut": "778479907",
        "password": "123456",
        "phone": "+56995188178",
        "frecuencytracking": 5,
        "propietario": "76069087",
        "createdAt": "2022-03-23T00:52:13.284Z",
        "updatedAt": "2022-09-27T15:00:37.410Z",
        "__v": 0
    },
    "estados": [
        {
            "location": {
                "coordinates": [
                    -33.4070736,
                    -70.7251287
                ],
                "type": "Point"
            },
            "tiporecurso": "id",
            "notificar": true,
            "whatsapp": true,
            "notificar_integraciones": [
                "delpa-system-emails",
                "retiro-delpa-system",
                "beetrack"
            ],
            "metadatos": [
                {
                    "tipo_accion": "carga-imagenes",
                    "titulo": "Foto de la carga",
                    "opciones": [],
                    "valores": [
                        {
                            "tipo": "",
                            "uri": "file:///data/user/0/com.delpa.conductordelpa/cache/ImageManipulator/eae24be2-04fd-4f95-acb6-10fbe9fb55a7.jpg",
                            "fechacreacion": "2022-05-13",
                            "url": "https://s3.us-east-1.amazonaws.com/urrapp/movil%2F1652453096863.jpg",
                            "lat": -33.4070768,
                            "lng": -70.7251608
                        },
                        {
                            "tipo": "",
                            "uri": "file:///data/user/0/com.delpa.conductordelpa/cache/ImageManipulator/7640de50-fee9-4e58-b092-b4351f4aba9f.jpg",
                            "fechacreacion": "2022-05-13",
                            "url": "https://s3.us-east-1.amazonaws.com/urrapp/movil%2F1652453101207.jpg",
                            "lat": -33.4070768,
                            "lng": -70.7251608
                        }
                    ],
                    "valor": "",
                    "required": false,
                    "_id": "6256efa2509b7c328bd175c0",
                    "idsubestado": "6256ef99509b7c328bd175b2",
                    "createdAt": "2022-04-13T15:43:30.758Z",
                    "updatedAt": "2022-04-13T15:43:35.604Z"
                }
            ],
            "publico": true,
            "notificar_planel_flota": false,
            "auditado": false,
            "_id": "627e6ef2ddde2012aff219a4",
            "idrecurso": "627e5e6feeae55dd057e6506",
            "id_subestado": "6256ef99509b7c328bd175b2",
            "subestado": "Iniciar ruta",
            "subestado_color": "#0a48cc",
            "codigo_subestado": "2",
            "estado_color": "#2a51af",
            "fecha": "2022-05-13T14:45:06.626+00:00",
            "idestado": "6256ef5e509b7c328bd17577",
            "estado": "En tránsito",
            "codigo_estado": "3",
            "canal": "app_conductor",
            "tipo_creador": "conductor",
            "id_creador": "623a6f3d642a32659e30ec05",
            "identificador_creador": "PFTW53 BOXER",
            "createdAt": "2022-05-13T14:45:06.630Z",
            "updatedAt": "2022-05-13T14:46:06.104Z"
        }
    ],
    "manifiestos_hijo": [
        {
            "confirmados": [],
            "metadata": [],
            "norecibidos": [],
            "pedidos": [
                "627e5e6feeae55dd057e6506"
            ],
            "parent": [],
            "_id": "627e6991ddde2012aff20e32",
            "id": "7215",
            "id_delpa": "164857",
            "operador": "76069087",
            "operador_nombre": "ANGEMO LTDA",
            "fechadespacho": "2022-05-13",
            "status": "solicitado",
            "tipo": "submanifiesto",
            "propietario": "76069087",
            "fechasignacion": "Fri May 13 2022 14:31:10 GMT+0000 (Coordinated Universal Time)",
            "idvehiculo": "623a6f3d642a32659e30ec05",
            "vehiculoasignado": "PFTW53 BOXER"
        }
    ],
    "manifiestos_padre": [
        {
            "confirmados": [],
            "metadata": [],
            "norecibidos": [],
            "pedidos": [],
            "parent": [],
            "_id": "62ab7d1f1eca15bac59571a1",
            "id": "616",
            "propietario": "76163495K",
            "asignacion": "No disponible",
            "fechadespacho": "2022-06-17",
            "idvehiculo": "",
            "operador": "76069087",
            "operador_nombre": "Delpa Servicios Logísticos",
            "status": "1",
            "vehiculoasignado": ""
        }
    ],
    "etiquetas": [
        {
            "_id": "636e081e54d87621f0f6ecef",
            "idrecurso": "627e5e6feeae55dd057e6506",
            "numero": 1,
            "identificador": "referencia",
            "ot": "697930",
            "createdAt": "2022-11-11T08:30:22.207Z",
            "updatedAt": "2022-11-11T08:30:22.207Z"
        }
    ]
}} />
    </Card.Body>
</Card>


          </Col>

          <Col md={5}>
          <h4>Lenguajes</h4>

          <Tabs defaultActiveKey="php" id="uncontrolled-tab-example" className="mb-3">
<Tab eventKey="php" title="PHP">
    <h4>Guzzle</h4>
<CopyBlock
      text={`<?php
    $client = new \GuzzleHttp\Client();
    $response = $client->request('GET', '${datos.server}/ordenes/{referencia}', [
        'headers' => [
          'Accept' => 'application/json',
          'Authorization' => 'Bearer XXXX',
        ]
    ]);
    echo $response->getBody();
?>`}
      language="php"
      showLineNumbers={false}
      theme={ocean}
      customStyle={{ fontSize: 14 }}
    />
  </Tab>
<Tab eventKey="javascript" title="Javascript">

<CopyBlock
      text={`
   fetch('${datos.server}/ordenes/{referencia}', {
        method:'GET',
        headers:{
            'Content-Type': 'application/json',
            'Authorization' => 'Bearer XXXX',
        }
    })
    .then(res => res.json())
    .then(respuesta => respuesta)
    .catch(error => error)
    `}
      language="javascript"
      showLineNumbers={false}
      theme={ocean}
      customStyle={{ fontSize: 14 }}

    />
  </Tab>
  
</Tabs>
          
<h4 className="mt-3">Header</h4>

<Card className="mb-3">
    <Card.Body>
        <h6 className="mb-0">Bearer <span style={{ fontWeight: 100 }}>String</span> <span className="text-danger">required</span></h6>
    </Card.Body>
</Card>

          </Col>
      </Row>
      
    </main>
  </div>
</div>
    </div>
        )
    }
}






export default PedidosDetalle