import React, { Component} from 'react'
import { connect } from 'react-redux'
import Header from '../Header'
import Nav from '../Nav'
import ReactJson from 'react-json-view'
import  datos  from '../../lib/global/data'
import Button from 'react-bootstrap/Button'
import { Row, Col, Card } from 'react-bootstrap'
import { CopyBlock, dracula, ocean } from "react-code-blocks";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

class Cotizar extends Component {
    constructor(){
        super()
        this.state = {
            code: '',
            language: ''
        }
    }
    render(){

        return(
            <div className="fluid">
       <Header/>   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Cotizar</h1>
      </div>

      <Row className="mb-5">
          <Col md={7}>

<h6><Button variant="primary" size="sm">GET</Button> /tarifas</h6>
<hr />

<h6>Parámetros</h6>
<Card className="mb-3">
    <Card.Body>
        <h6 className="mb-0">destino <span style={{ fontWeight: 100 }}>String</span> <span className="text-danger">required</span> <span style={{ fontWeight: 100 }}>Dirección de entrega</span></h6>
        <hr />
        <h6 className="mb-0">peso <span style={{ fontWeight: 100 }}>String</span> <span className="text-danger">required</span> <span style={{ fontWeight: 100 }}>Peso en KG</span></h6>
        <hr />
        <h6 className="mb-0">volumen <span style={{ fontWeight: 100 }}>String</span> <span className="text-danger">required</span> <span style={{ fontWeight: 100 }}>Volumen en M3</span></h6>
    </Card.Body>
</Card>

<h6>Respuesta</h6>

<Card className="mb-3">
    <Card.Body>
        <h6 className="mb-0">STATUS <span className="text-success"><i className="text-success fas fa-check-circle"></i> 200</span> </h6>
    </Card.Body>
</Card>

<Card className="mb-3" style={{ background:'#0c0d0e' }}>
    <Card.Body>
    <ReactJson 
name={false}
  theme="brewer"
  indentWidth="4"
  src={[
    {
        "idcotizacion": "627593530175c962d095914d",
        "tarifa": 2600,
        "tarifa_encriptada": "yg9FXghe+lbiELeq82OtcQ==",
        "moneda": "CLP",
        "familia": "Ecommerce",
        "negocio": "Terrestre Nacional",
        "servicio": "Paquetería Ecommerce",
        "metorolitana": false,
        "destino_comuna": "santiago",
        "destino_region": "región metropolitana",
        "destino_pais": "chile",
        "destino_lat": -33.4583484,
        "destino_lng": -70.6554388
    }
]} />
    </Card.Body>
</Card>


          </Col>

          <Col md={5}>
          <h4>Lenguajes</h4>

          <Tabs defaultActiveKey="php" id="uncontrolled-tab-example" className="mb-3">
<Tab eventKey="php" title="PHP">
    <h4>Guzzle</h4>
<CopyBlock
      text={`<?php
    $client = new \GuzzleHttp\Client();
    $response = $client->request('GET', '${datos.server}/tarifas?destino=manuel antonio matta 747, santiago&peso=1&volumen=0.02', [
        'headers' => [
          'Accept' => 'application/json',
          'Authorization' => 'Bearer XXXX',
        ]
    ]);
    echo $response->getBody();
?>`}
      language="php"
      showLineNumbers={false}
      theme={ocean}
      customStyle={{ fontSize: 14 }}
    />
  </Tab>
<Tab eventKey="javascript" title="Javascript">

<CopyBlock
      text={`
   fetch('${datos.server}/tarifas?destino=manuel antonio matta 747, santiago&peso=1&volumen=0.02', {
        method:'GET',
        headers:{
            'Content-Type': 'application/json',
            'Authorization' => 'Bearer XXXX',
        }
    })
    .then(res => res.json())
    .then(respuesta => respuesta)
    .catch(error => error)
    `}
      language="javascript"
      showLineNumbers={false}
      theme={ocean}
      customStyle={{ fontSize: 14 }}

    />
  </Tab>
  
</Tabs>
          
<h4 className="mt-3">Header</h4>

<Card className="mb-3">
    <Card.Body>
        <h6 className="mb-0">Bearer <span style={{ fontWeight: 100 }}>String</span> <span className="text-danger">required</span></h6>
    </Card.Body>
</Card>

          </Col>
      </Row>
      
    </main>
  </div>
</div>
    </div>
        )
    }
}






export default Cotizar