import React, { Component} from 'react'
import { connect } from 'react-redux'
import Header from '../Header'
import Nav from '../Nav'
import ReactJson from 'react-json-view'
import  datos  from '../../lib/global/data'
import Button from 'react-bootstrap/Button'
import { Row, Col, Card } from 'react-bootstrap'
import { CopyBlock, dracula, ocean } from "react-code-blocks";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

class PedidosEditar extends Component {
    constructor(){
        super()
        this.state = {
            code: '',
            language: ''
        }
    }
    render(){

        return(
            <div className="fluid">
       <Header/>   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Editar pedido</h1>
      </div>



      <Row className="mb-5">
          <Col md={7}>


<h6><Button variant="info" size="sm">POST</Button> /ordenes</h6>
<hr />

<h6>Parámetros </h6>
<Card className="mb-3">
    <Card.Body>
        <h6 className="mb-0">destinatario <span style={{ fontWeight: 100 }}>String</span> <span className="text-primary">optional</span> <span style={{ fontWeight: 100 }}>Nombre del destinatario</span></h6>
        <hr/>
        <h6 className="mb-0">telefono <span style={{ fontWeight: 100 }}>String</span> <span className="text-primary">optional</span> <span style={{ fontWeight: 100 }}>Número de teléfono {"(569369XXXXX)"}</span></h6>
        <hr/>
        <h6 className="mb-0">email <span style={{ fontWeight: 100 }}>String</span> <span className="text-primary">optional</span> <span style={{ fontWeight: 100 }}>Email del destinatario</span></h6>
        <hr/>
        <h6 className="mb-0">peso <span style={{ fontWeight: 100 }}>Int</span> <span className="text-primary">optional</span> <span style={{ fontWeight: 100 }}>Peso de la carga en KG.</span></h6>
        <hr/>
        <h6 className="mb-0">volumen <span style={{ fontWeight: 100 }}>Int</span> <span className="text-primary">optional</span> <span style={{ fontWeight: 100 }}>Volumen de la carga en M3</span></h6>
        <hr/>
        <h6 className="mb-0">destino <span style={{ fontWeight: 100 }}>String</span> <span className="text-primary">optional</span> <span style={{ fontWeight: 100 }}>Dirección de entrega</span></h6>
        <hr/>
        <h6 className="mb-0">observaciones <span style={{ fontWeight: 100 }}>String</span> <span className="text-primary">optional</span> <span style={{ fontWeight: 100 }}>Observaciones de la dirección de entrega</span></h6>
        <hr/>
        <h6 className="mb-0">bultos <span style={{ fontWeight: 100 }}>Int</span> <span className="text-primary">optional</span> <span style={{ fontWeight: 100 }}>Cantida de bultos</span></h6>
        <hr/>
        <h6 className="mb-0">refrigerado <span style={{ fontWeight: 100 }}>Int</span> <span className="text-primary">optional</span> <span style={{ fontWeight: 100 }}>Cantida de bultos refrigerados</span></h6>
        <hr/>
        <h6 className="mb-0">fechadespacho <span style={{ fontWeight: 100, lineHeight: 2 }}>String</span> <span className="text-primary">optional</span> <span style={{ fontWeight: 100 }}>Fecha designada para despachar el pedido {"(Formato YYYY-MM-DD)"}</span></h6>
        <hr/>
        <h6 className="mb-0">nota <span style={{ fontWeight: 100 }}>String</span> <span className="text-primary">optional</span> <span style={{ fontWeight: 100 }}>Notas especiales del pedido</span></h6>
    </Card.Body>
</Card>
<Card className="mb-3">
    <Card.Body>
        <h6 className="mb-0">STATUS <span className="text-success"><i className="text-success fas fa-check-circle"></i> 200</span> </h6>
    </Card.Body>
</Card>

<Card className="mb-3" style={{ background:'#0c0d0e' }}>
    <Card.Body>
    <ReactJson 
name={false}
  theme="brewer"
  indentWidth="4"
  src={{
    "pedido": "XXXXXX",
    "propietario": "222222222",
    "bultos": "4",
    "nota": "",
    "tiposervicio": "100",
    "fechadespacho": "2022-10-10",
    "lat": "",
    "lng": "",
    "comuna": "",
    "ot": "",
    "orden": {
        "number": "asf",
        "date_modified_gmt": "2022-11-11T06:19:56.473-04:00",
        "date_completed": "2022-11-11T06:19:56.473-04:00",
        "date_completed_gmt": "2022-11-11T06:19:56.473-04:00",
        "id": "asf",
        "date_created": "2022-11-11T06:19:56.473-04:00",
        "shipping": {
            "first_name": "Abdías Laguna",
            "last_name": "",
            "address_1": "manuel antonio matta 747, santiago",
            "address_2": "",
            "email": "alg@g.a",
            "phone": ""
        },
        "billing": {
            "first_name": "Abdías Laguna",
            "last_name": "",
            "address_1": "manuel antonio matta 747, santiago",
            "address_2": "",
            "email": "ejemplo@delpa.cl",
            "phone": ""
        },
        "meta_data": [
            {
                "id": 111,
                "key": "dlp_tarifa_cotizada",
                "value": "2600"
            },
            {
                "id": 112,
                "key": "dlp_volumen_total",
                "value": "0.02"
            },
            {
                "id": 113,
                "key": "dlp_peso_total",
                "value": "1"
            }
        ]
    },
    "refrigerado": ""
}} />
    </Card.Body>
</Card>


          </Col>

          <Col md={5}>
          <h4>Lenguajes</h4>

          <Tabs defaultActiveKey="php" id="uncontrolled-tab-example" className="mb-3">
<Tab eventKey="php" title="PHP">
    <h4>Guzzle</h4>
<CopyBlock
      text={`<?php
    $client = new \GuzzleHttp\Client();
    $response = $client->request('PUT', '${datos.server}/ordenes', [
        'headers' => [
          'Accept' => 'application/json',
          'Authorization' => 'Bearer XXXX',
        ],
        'body' => json_encode($payload)
    ]);
    echo $response->getBody();
?>`}
      language="php"
      showLineNumbers={false}
      theme={ocean}
      customStyle={{ fontSize: 14 }}
    />
  </Tab>
<Tab eventKey="javascript" title="Javascript">

<CopyBlock
      text={`
   fetch('${datos.server}/ordenes', {
        method:'PUT',
        body: JSON.stringify(payload),
        headers:{
            'Content-Type': 'application/json',
            'Authorization' => 'Bearer XXXX',
        }
    })
    .then(res => res.json())
    .then(respuesta => respuesta)
    .catch(error => error)
    `}
      language="javascript"
      showLineNumbers={false}
      theme={ocean}
      customStyle={{ fontSize: 14 }}

    />
  </Tab>
  
</Tabs>
          
<h4 className="mt-3">Header</h4>

<Card className="mb-3">
    <Card.Body>
        <h6 className="mb-0">Bearer <span style={{ fontWeight: 100 }}>String</span> <span className="text-danger">required</span></h6>
    </Card.Body>
</Card>

          </Col>
      </Row>
      
    </main>
  </div>
</div>
    </div>
        )
    }
}






export default PedidosEditar