import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion'
import { Card } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'

class Nav extends Component {
    constructor(props){
        super(props)

        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            errorLogin: '',
            user: JSON.parse(localStorage.getItem('rmkt_delpa_user')),
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    procesarOpciones(){
      const { user } = this.state

      let opciones = []
      if(user.matriz){
        if(user.matriz.esquema){
          if(Array.isArray(user.matriz.esquema)){
            if(user.matriz.esquema.length > 0){
              user.matriz.esquema.map(valor => {
                opciones.push(valor)
              })
            }
          }
        }
      }




      return opciones.map(elemento => {
        // <img style={{ width: 30 }} src="evaluaciones.jpg" />

        if(elemento.slug === 'habilidades') return <Accordion>
          <Card>
      <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12 }} eventKey="1">
        <p className="nav-link mb-0"><i className={this.iconbytype(elemento.slug)}></i> Habilidades <i className="flecha fas fa-arrow-circle-right"></i></p>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body style={{ padding: '8px 0px', fontSize: 12, background: '#e0e0e0' }}>
        <Link to={elemento.slug} className="nav-link"><i className={this.iconbytype(elemento.slug)}></i> Matrices </Link>
        <Link to="/biblioteca-habilidades" className="nav-link"><i className={this.iconbytype(elemento.slug)}></i> Biblioteca </Link>
        </Card.Body>
      </Accordion.Collapse>
      </Card>
  </Accordion>

        return <li className="nav-item">
        <Link to={elemento.slug} className="nav-link"><i className="fas fa-users"></i> Contactos</Link>
      </li>
      })
    }

    iconbytype(slug){
      switch(slug){
        case'evaluaciones':
          return "fas fa-users-cog";
        case'usuarios':
          return "fas fa-users";
          case'areas':
          return "fas fa-users";
        case'roles':
          return "fas fa-users";
        case'cargos':
          return "fas fa-users";
        case'matrices':
          return "fas fa-user-shield";
        default:
          break;
      }
    }

    render(){

        return(
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
      <div className="position-sticky pt-3">
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link to="/" className="nav-link"> Inicio</Link>
          </li>
          <li className="nav-item">
            <Link to="/autenticacion" className="nav-link"> Autenticación</Link>
          </li>
          <Accordion defaultActiveKey="1">
          <Card>
      <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12 }} eventKey="1">
        <p className="nav-link mb-0"> Órdenes <i className="flecha fas fa-arrow-circle-down"></i></p>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body style={{ padding: '8px 0px', fontSize: 12, background: '#e0e0e0' }}>
        <Link to="/pedidos-list" className="nav-link">Listar Órdenes <Button style={{ fontSize: 8, float: 'right' }} variant="warning" size="sm">POST</Button></Link>
        <Link to="/pedidos-consultar" className="nav-link">Consultar orden <Button style={{ fontSize: 8, float: 'right' }} variant="primary" size="sm">GET</Button></Link>
        <Link to="/pedidos-crear" className="nav-link">Crear orden <Button style={{ fontSize: 8, float: 'right' }} variant="warning" size="sm">POST</Button></Link>
        <Link to="/pedidos-editar" className="nav-link">Editar orden <Button style={{ fontSize: 8, float: 'right' }} variant="info" size="sm">PUT</Button></Link>
        { /** <Link to="/pedidos-eliminar" className="nav-link">Eliminar Pedido <Button style={{ fontSize: 8, float: 'right' }} variant="danger" size="sm">DELETE</Button></Link> */}
        </Card.Body>
      </Accordion.Collapse>
      </Card>
  </Accordion>
          <Accordion defaultActiveKey="1">
          <Card>
      <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12 }} eventKey="1">
        <p className="nav-link mb-0"> Webhooks <i className="flecha fas fa-arrow-circle-down"></i></p>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body style={{ padding: '8px 0px', fontSize: 12, background: '#e0e0e0' }}>
        <Link to="/webhooks-list" className="nav-link">Listar Webhooks <Button style={{ fontSize: 8, float: 'right' }} variant="warning" size="sm">POST</Button></Link>
        <Link to="/webhooks-consultar" className="nav-link">Consultar Webhook <Button style={{ fontSize: 8, float: 'right' }} variant="primary" size="sm">GET</Button></Link>
        <Link to="/webhooks-crear" className="nav-link">Crear Webhook <Button style={{ fontSize: 8, float: 'right' }} variant="warning" size="sm">POST</Button></Link>
        <Link to="/webhooks-editar" className="nav-link">Editar Webhook <Button style={{ fontSize: 8, float: 'right' }} variant="info" size="sm">PUT</Button></Link>
        <Link to="/webhooks-eliminar" className="nav-link">Eliminar Webhook <Button style={{ fontSize: 8, float: 'right' }} variant="danger" size="sm">DELETE</Button></Link>
        </Card.Body>
      </Accordion.Collapse>
      </Card>
  </Accordion>

  <Accordion defaultActiveKey="1">
          <Card>
      <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12 }} eventKey="1">
        <p className="nav-link mb-0"> Tarifas <i className="flecha fas fa-arrow-circle-down"></i></p>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body style={{ padding: '8px 0px', fontSize: 12, background: '#e0e0e0' }}>
        <Link to="/cotizar" className="nav-link">Cotizar tarifa <Button style={{ fontSize: 8, float: 'right' }} variant="primary" size="sm">GET</Button></Link>
        </Card.Body>
      </Accordion.Collapse>
      </Card>
  </Accordion>

        </ul>

      </div>
    </nav>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Nav);