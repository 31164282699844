import React, { Component} from 'react'
import { connect } from 'react-redux'
import Header from '../Header'
import Nav from '../Nav'
import ReactJson from 'react-json-view'
import  datos  from '../../lib/global/data'
import Button from 'react-bootstrap/Button'
import { Card, Tab, Tabs } from 'react-bootstrap'
import { CopyBlock, ocean } from 'react-code-blocks'

class Autenticacion extends Component {

    render(){

        return(
            <div className="fluid">
       <Header/>   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Autenticación</h1>
      </div>
      <p>Para utilizar el API necesitarás una llave que deberás enviar como tipo de autorización Bearer token.</p>

      <hr />

      <h6>Ejemplo</h6>
      <Tabs defaultActiveKey="php" id="uncontrolled-tab-example" className="mb-3">
<Tab eventKey="php" title="PHP">
    <h4>Guzzle</h4>
<CopyBlock
      text={`<?php
    $client = new \GuzzleHttp\Client();
    $response = $client->request('GET', '${datos.server}/{endpoint}', [
        'headers' => [
          'Accept' => 'application/json',
          'Authorization' => 'Bearer 5db3808948c097478d4ecd6364a918bf1a795242',
        ],
    ]);
    echo $response->getBody();
?>`}
      language="php"
      showLineNumbers={false}
      theme={ocean}
      customStyle={{ fontSize: 14 }}
    />
  </Tab>
<Tab eventKey="javascript" title="Javascript">

<CopyBlock
      text={`
   fetch('${datos.server}/{endpoint}', {
        method:'GET',
        headers:{
            'Content-Type': 'application/json',
            'Authorization' => 'Bearer 5db3808948c097478d4ecd6364a918bf1a795242',
        }
    })
    .then(res => res.json())
    .then(respuesta => respuesta)
    .catch(error => error)
    `}
      language="javascript"
      showLineNumbers={false}
      theme={ocean}
      customStyle={{ fontSize: 14 }}

    />
  </Tab>
  
</Tabs>
    </main>
  </div>
</div>
    </div>
        )
    }
}


export default Autenticacion