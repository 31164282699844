import React, { Component} from 'react'
import { connect } from 'react-redux'
import Header from '../Header'
import Nav from '../Nav'
import ReactJson from 'react-json-view'
import  datos  from '../../lib/global/data'
import Button from 'react-bootstrap/Button'
import { Row, Col, Card } from 'react-bootstrap'
import { CopyBlock, dracula, ocean } from "react-code-blocks";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

class WebhooksListado extends Component {
    constructor(){
        super()
        this.state = {
            code: '',
            language: ''
        }
    }
    render(){

        return(
            <div className="fluid">
       <Header/>   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Listar webhooks</h1>
      </div>

      <Row className="mb-5">
          <Col md={7}>

<h6><Button variant="warning" size="sm">POST</Button> /webhooks/list</h6>
<hr />

<h6>Parámetros</h6>
<Card className="mb-3">
    <Card.Body>
        <h6 className="mb-0">ItemsPerPage <span style={{ fontWeight: 100 }}>Int</span> <span className="text-danger">required</span> <span style={{ fontWeight: 100 }}>Cantidad de registros por página</span></h6>
        <hr />
        <h6 className="mb-0">Page <span style={{ fontWeight: 100 }}>Int</span> <span className="text-danger">required</span> <span style={{ fontWeight: 100 }}>Número de la página actual</span></h6>
    </Card.Body>
</Card>

<h6>Respuesta</h6>

<Card className="mb-3">
    <Card.Body>
        <h6 className="mb-0">STATUS <span className="text-success"><i className="text-success fas fa-check-circle"></i> 200</span> </h6>
    </Card.Body>
</Card>

<Card className="mb-3" style={{ background:'#0c0d0e' }}>
    <Card.Body>
    <ReactJson 
name={false}
  theme="brewer"
  indentWidth="4"
  src={{
    "total": 10,
    "list": [
        {
            "_id": "631f9d087ae90f236bd0d79a",
            "propietario": "222222222",
            "evento": "nuevo-estado",
            "url": "https://{url}",
            "format": "json",
            "active": true,
            "version": "2022-09",
            "id_creador": "62ac14bd382419a1933d53b3",
            "createdAt": "2022-08-17T18:16:59.432Z",
            "updatedAt": "2022-08-18T08:44:38.425Z",
            "ultima_fecha_pausado": "2022-08-18T02:56:24.562-04:00"
        },
        {
            "_id": "631b4d037ae90f236bd0d777",
            "propietario": "222222222",
            "evento": "nuevo-lote",
            "url": "https://{url}",
            "format": "json",
            "active": true,
            "version": "2022-09",
            "id_creador": "62ac14bd382419a1933d53b3",
            "createdAt": "2022-08-17T18:16:59.432Z",
            "updatedAt": "2022-08-18T08:44:38.425Z",
            "ultima_fecha_pausado": "2022-08-18T02:56:24.562-04:00"
        }
    ]
}} />
    </Card.Body>
</Card>


          </Col>

          <Col md={5}>
          <h4>Lenguajes</h4>

          <Tabs defaultActiveKey="php" id="uncontrolled-tab-example" className="mb-3">
<Tab eventKey="php" title="PHP">
    <h4>Guzzle</h4>
<CopyBlock
      text={`<?php
    $client = new \GuzzleHttp\Client();
    $payload = [
        "ItemsPerPage" => 10,
        "Page" => 2
    ];
    $response = $client->request('POST', '${datos.server}/webhooks/list', [
        'headers' => [
          'Accept' => 'application/json',
          'Authorization' => 'Bearer XXXX',
        ],
        'body' => json_encode($payload)
    ]);
    echo $response->getBody();
?>`}
      language="php"
      showLineNumbers={false}
      theme={ocean}
      customStyle={{ fontSize: 14 }}
    />
  </Tab>
<Tab eventKey="javascript" title="Javascript">

<CopyBlock
      text={`
   fetch('${datos.server}/webhooks/list', {
        method:'POST',
        body: JSON.stringify({
            ItemsPerPage: 10,
            Page: 2
        }),
        headers:{
            'Content-Type': 'application/json',
            'Authorization' => 'Bearer XXXX',
        }
    })
    .then(res => res.json())
    .then(respuesta => respuesta)
    .catch(error => error)
    `}
      language="javascript"
      showLineNumbers={false}
      theme={ocean}
      customStyle={{ fontSize: 14 }}

    />
  </Tab>
  
</Tabs>
          
<h4 className="mt-3">Header</h4>

<Card className="mb-3">
    <Card.Body>
        <h6 className="mb-0">Bearer <span style={{ fontWeight: 100 }}>String</span> <span className="text-danger">required</span></h6>
    </Card.Body>
</Card>

          </Col>
      </Row>
      
    </main>
  </div>
</div>
    </div>
        )
    }
}






export default WebhooksListado