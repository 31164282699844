import React, { Component} from 'react'
import { userService } from '../../services/user.service'
import { Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import  datos  from '../../lib/global/data'

class Home extends Component {

    render(){

        return(
            <div className="fluid">
       <Header/>   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Introducción</h1>
        
        
      </div>

      <p>En estas páginas encontrarás la documentación necesaria para utilizar nuestra API </p>

        <p>A través de estos endpoints podrás acceder a la gran mayoría de la información de tu empresa.</p>

        <h6>Producción</h6>
        <p className="text-primary">{datos.server}</p>
        
        <h6>Desarrollo</h6>
        <p className="text-primary">{datos.server_debug}</p>
    </main>
  </div>
</div>
    </div>
        )
    }
}

export default Home;