import React, { Component} from 'react'
import { connect } from 'react-redux'
import Header from '../Header'
import Nav from '../Nav'
import ReactJson from 'react-json-view'
import  datos  from '../../lib/global/data'
import Button from 'react-bootstrap/Button'
import { Row, Col, Card } from 'react-bootstrap'
import { CopyBlock, dracula, ocean } from "react-code-blocks";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

class PedidosEliminar extends Component {
    constructor(){
        super()
        this.state = {
            code: '',
            language: ''
        }
    }
    render(){

        return(
            <div className="fluid">
       <Header/>   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Eliminar un pedido</h1>
      </div>



      <Row className="mb-5">
          <Col md={7}>


<h6><Button variant="danger" size="sm">DELETE</Button> /ordenes/{"{referencia}"}</h6>
<hr />

<h6>Parámetros</h6>
<Card className="mb-3">
    <Card.Body>
        <h6 className="mb-0">referencia <span style={{ fontWeight: 100 }}>String</span> <span className="text-danger">required</span> <span style={{ fontWeight: 100 }}>Referencia única del pedido</span></h6>
    </Card.Body>
</Card>

<h6>Respuesta</h6>
<Card className="mb-3" style={{ background:'#0c0d0e' }}>
    <Card.Body>
    <ReactJson 
name={false}
  theme="brewer"
  indentWidth="4"
  src={{
    message: "Eliminado exitosamente, recuerde que este recurso aún puede haber generado gastos de cobranza, comunicarse por administración para estos efectos.",
    success: true
}} />
    </Card.Body>
</Card>


          </Col>

          <Col md={5}>
          <h4>Lenguajes</h4>

          <Tabs defaultActiveKey="php" id="uncontrolled-tab-example" className="mb-3">
<Tab eventKey="php" title="PHP">
    <h4>Guzzle</h4>
<CopyBlock
      text={`<?php
    $client = new \GuzzleHttp\Client();
    $response = $client->request('DELETE', '${datos.server}/ordenes/{referencia}', [
        'headers' => [
          'Accept' => 'application/json',
          'Authorization' => 'Bearer XXXX',
        ]
    ]);
    echo $response->getBody();
?>`}
      language="php"
      showLineNumbers={false}
      theme={ocean}
      customStyle={{ fontSize: 14 }}
    />
  </Tab>
<Tab eventKey="javascript" title="Javascript">

<CopyBlock
      text={`
   fetch('${datos.server}/ordenes/{referencia}', {
        method:'DELETE',
        headers:{
            'Content-Type': 'application/json',
            'Authorization' => 'Bearer XXXX',
        }
    })
    .then(res => res.json())
    .then(respuesta => respuesta)
    .catch(error => error)
    `}
      language="javascript"
      showLineNumbers={false}
      theme={ocean}
      customStyle={{ fontSize: 14 }}

    />
  </Tab>
  
</Tabs>
          
<h4 className="mt-3">Header</h4>

<Card className="mb-3">
    <Card.Body>
        <h6 className="mb-0">Bearer <span style={{ fontWeight: 100 }}>String</span> <span className="text-danger">required</span></h6>
    </Card.Body>
</Card>

          </Col>
      </Row>
      
    </main>
  </div>
</div>
    </div>
        )
    }
}






export default PedidosEliminar