import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import store from './redux/store';
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home';
import Autenticacion from './components/Autenticacion';
import PedidosListado from './components/Pedidos/listado';
import PedidosDetalle from './components/Pedidos/detalle';
import PedidosCrear from './components/Pedidos/crear';
import PedidosEditar from './components/Pedidos/editar';
import PedidosEliminar from './components/Pedidos/eliminar';
import Cotizar from './components/Cotizar/cotizar';
import WebhooksListado from './components/Webhooks/listado';
import WebhooksDetalle from './components/Webhooks/detalle';
import WebhooksCrear from './components/Webhooks/crear';
import WebhooksEditar from './components/Webhooks/editar';
import WebhooksEliminar from './components/Webhooks/eliminar';

require('dotenv').config()

const App = (
  <Provider store={store}>
    <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/pedidos-list" component={PedidosListado} />
      <Route exact path="/autenticacion" component={Autenticacion} />
      <Route exact path="/pedidos-consultar" component={PedidosDetalle} />
      <Route exact path="/pedidos-crear" component={PedidosCrear} />
      <Route exact path="/pedidos-editar" component={PedidosEditar} />
      <Route exact path="/pedidos-eliminar" component={PedidosEliminar} />
      <Route exact path="/webhooks-list" component={WebhooksListado} />
      <Route exact path="/webhooks-consultar" component={WebhooksDetalle} />
      <Route exact path="/webhooks-crear" component={WebhooksCrear} />
      <Route exact path="/webhooks-editar" component={WebhooksEditar} />
      <Route exact path="/webhooks-eliminar" component={WebhooksEliminar} />
      <Route exact path="/cotizar" component={Cotizar} />
    </Switch>
  </BrowserRouter>
  </Provider>
) 

ReactDOM.render(App, document.getElementById('root'))
reportWebVitals();
