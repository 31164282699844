import React, { Component} from 'react'
import { connect } from 'react-redux'
import Header from '../Header'
import Nav from '../Nav'
import ReactJson from 'react-json-view'
import  datos  from '../../lib/global/data'
import Button from 'react-bootstrap/Button'
import { Row, Col, Card } from 'react-bootstrap'
import { CopyBlock, dracula, ocean } from "react-code-blocks";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

class PedidosListado extends Component {
    constructor(){
        super()
        this.state = {
            code: '',
            language: ''
        }
    }
    render(){

        return(
            <div className="fluid">
       <Header/>   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Listar pedidos</h1>
      </div>



      <Row className="mb-5">
          <Col md={7}>


<h6><Button variant="warning" size="sm">POST</Button> /ordenes/list</h6>
<hr />

<h6>Parámetros</h6>
<Card className="mb-3">
    <Card.Body>
        <h6 className="mb-0">ItemsPerPage <span style={{ fontWeight: 100 }}>Int</span> <span className="text-danger">required</span> <span style={{ fontWeight: 100 }}>Cantidad de registros por página</span></h6>
        <hr />
        <h6 className="mb-0">Page <span style={{ fontWeight: 100 }}>Int</span> <span className="text-danger">required</span> <span style={{ fontWeight: 100 }}>Número de la página actual</span></h6>
    </Card.Body>
</Card>

<h6>Respuesta</h6>

<Card className="mb-3">
    <Card.Body>
        <h6 className="mb-0">STATUS <span className="text-success"><i className="text-success fas fa-check-circle"></i> 200</span> </h6>
    </Card.Body>
</Card>

<Card className="mb-3" style={{ background:'#0c0d0e' }}>
    <Card.Body>
    <ReactJson 
name={false}
  theme="brewer"
  indentWidth="4"
  src={{
    "total": 10,
    "list": [
        {
            "_id": "636d41a998d4b455c3d3407f",
            "pedido": "8703994120",
            "propietario": "222222222",
            "bultos": "1",
            "nota": "1 CAMPANA-MX-U160                         ",
            "tiposervicio": "103",
            "ot": "737773",
            "orden": {
                "billing": {
                    "first_name": "Orlando Figueroa",
                    "state": "",
                    "email": "testing@delpa.com",
                    "phone": "56XXXXXXXXX",
                    "last_name": "",
                    "company": "",
                    "address_1": "Grajales 2045  604 , Santiago, Región Metropolitana",
                    "address_2": "",
                    "city": "Santiago",
                    "postcode": "",
                    "country": "Chile"
                }
            },
            "refrigerado": "0",
            "__v": 0,
            "idconductor": "632da96af6b56c7c3ed5c82e",
            "estado_entrega": {
                "_id": "6256efcc509b7c328bd175dc",
                "codigo_estado": "5",
                "titulo": "Entregado",
                "color": "#0cbe3b",
                "fecha": 1668106688335
            },
            "sub_estado_entrega": {
                "_id": "6256f00a509b7c328bd1764a",
                "codigo_estado": "5",
                "titulo": "Entrega exitosa",
                "color": "#20aa55",
                "fecha": 1668106688335
            },
            "congelado": "0",
            "fechadespacho": "",
            "guia": "628045",
            "fechaentrega": "2022-11-10"
        }
    ]
}} />
    </Card.Body>
</Card>


          </Col>

          <Col md={5}>
          <h4>Lenguajes</h4>

          <Tabs defaultActiveKey="php" id="uncontrolled-tab-example" className="mb-3">
<Tab eventKey="php" title="PHP">
    <h4>Guzzle</h4>
<CopyBlock
      text={`<?php
    $client = new \GuzzleHttp\Client();
    $payload = [
        "ItemsPerPage" => 10,
        "Page" => 2
    ];
    $response = $client->request('POST', '${datos.server}/ordenes/list', [
        'headers' => [
          'Accept' => 'application/json',
          'Authorization' => 'Bearer XXXX',
        ],
        'body' => json_encode($payload)
    ]);
    echo $response->getBody();
?>`}
      language="php"
      showLineNumbers={false}
      theme={ocean}
      customStyle={{ fontSize: 14 }}
    />
  </Tab>
<Tab eventKey="javascript" title="Javascript">

<CopyBlock
      text={`
   fetch('${datos.server}/ordenes/list', {
        method:'POST',
        body: JSON.stringify({
            ItemsPerPage: 10,
            Page: 2
        }),
        headers:{
            'Content-Type': 'application/json',
            'Authorization' => 'Bearer XXXX',
        }
    })
    .then(res => res.json())
    .then(respuesta => respuesta)
    .catch(error => error)
    `}
      language="javascript"
      showLineNumbers={false}
      theme={ocean}
      customStyle={{ fontSize: 14 }}

    />
  </Tab>
  
</Tabs>
          
<h4 className="mt-3">Header</h4>

<Card className="mb-3">
    <Card.Body>
        <h6 className="mb-0">Bearer <span style={{ fontWeight: 100 }}>String</span> <span className="text-danger">required</span></h6>
    </Card.Body>
</Card>

          </Col>
      </Row>
      
    </main>
  </div>
</div>
    </div>
        )
    }
}






export default PedidosListado